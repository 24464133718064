import * as React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";


const Icon: React.FunctionComponent<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props} focusable="false" viewBox="0 0 86.28 38.62" aria-hidden="true">
            <path className="cls-1" d="M42,3.33A2.48,2.48,0,1,0,37.6,5.58l10.7,21A2.49,2.49,0,0,0,50.52,28a2.57,2.57,0,0,0,1.13-.27,2.49,2.49,0,0,0,1.08-3.35Z" />
            <path className="cls-2" d="M72.47,1.69a2.49,2.49,0,0,0-3.33,1.14L53.61,34.71a2.49,2.49,0,1,0,4.47,2.18L73.62,5A2.49,2.49,0,0,0,72.47,1.69Z" />
            <path className="cls-3" d="M83.22,6.55h0a1.11,1.11,0,0,0,.25-.74,1.09,1.09,0,0,0-.4-.89,1.52,1.52,0,0,0-1-.32H80.55V8.22h.91V7h.37l.79,1.2h1.07l-.93-1.33A1.13,1.13,0,0,0,83.22,6.55Zm-1.16-.3h-.6V5.37h.6c.44,0,.5.22.5.44S82.5,6.25,82.06,6.25Z" />
            <path className="cls-4" d="M81.91,2.13A4.37,4.37,0,1,0,86.28,6.5,4.37,4.37,0,0,0,81.91,2.13Zm0,7.58A3.22,3.22,0,1,1,85.13,6.5,3.22,3.22,0,0,1,81.91,9.71Z" />
            <path className="cls-5" d="M33.71,17.1c-.12,0-.23,0-.34,0s-.1,0-.16,0H19a2,2,0,0,0,0,4H31.19A13.11,13.11,0,0,1,5,20.52v-.93A13.14,13.14,0,0,1,18.1,6.46a2.49,2.49,0,0,0,0-5A18.12,18.12,0,0,0,0,19.59v.93a18.1,18.1,0,1,0,36.19,0v-.93A2.48,2.48,0,0,0,33.71,17.1Z" />
            <circle className="cls-6" cx="26.18" cy="3.98" r="3.98" />

        </SvgIcon>
    );
};

export default Icon;
