import {
    CatalogKindExploreContent,
    GroupsExplorerContent,
    ToolExplorerContent,
    DomainExplorerContent,
    ExploreLayout,
} from '@backstage-community/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
    return (
        <ExploreLayout
            title="Explore the eSmart Systems ecosystem"
            subtitle="Discover available systems, domains, groups, users and tools"
        >
            <ExploreLayout.Route path="systems" title="Systems">
                <CatalogKindExploreContent kind="system" />
            </ExploreLayout.Route>
            <ExploreLayout.Route path="domains" title="Domains">
                <DomainExplorerContent />
            </ExploreLayout.Route>
            <ExploreLayout.Route path="groups" title="Groups">
                <GroupsExplorerContent />
            </ExploreLayout.Route>
            <ExploreLayout.Route path="users" title="Users">
                <CatalogKindExploreContent kind="user" />
            </ExploreLayout.Route>
            <ExploreLayout.Route path="tools" title="Tools">
                <ToolExplorerContent />
            </ExploreLayout.Route>
        </ExploreLayout>
    );
};

export const explorePage = <ExplorePage />;