import * as React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";


const Icon: React.FunctionComponent<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props} focusable="false" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M0 11.865l2.995-3.953 11.208-4.557v-3.292l9.828 7.188-20.078 3.896v10.969l-3.953-1.141zM32 5.932v19.536l-7.672 6.531-12.401-4.073v4.073l-7.974-9.885 20.078 2.396v-17.26z" />
        </SvgIcon>
    );
};

export default Icon;