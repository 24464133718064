import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg viewBox="0 0 351 262" fill="none" className={classes.svg}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.44993 80.5141C10.6147 80.5141 12.6909 81.373 14.223 82.9024C15.755 84.4317 16.6176 86.5065 16.6214 88.6712V135.243C16.6674 136.344 16.4904 137.442 16.1008 138.473C15.7113 139.504 15.1173 140.445 14.3547 141.24C13.592 142.035 12.6764 142.668 11.6629 143.101C10.6494 143.533 9.55892 143.756 8.45707 143.756C7.35523 143.756 6.26476 143.533 5.25126 143.101C4.23775 142.668 3.32213 142.035 2.55947 141.24C1.7968 140.445 1.20285 139.504 0.813317 138.473C0.423786 137.442 0.246727 136.344 0.292788 135.243V88.6712C0.292788 86.5078 1.1522 84.433 2.68196 82.9033C4.21172 81.3735 6.28652 80.5141 8.44993 80.5141Z" fill="#4CA9A3" />
      <path d="M282.25 118.857C284.417 118.857 286.496 119.718 288.028 121.25C289.56 122.783 290.421 124.861 290.421 127.028V173.6C290.333 175.706 289.435 177.696 287.913 179.156C286.392 180.615 284.365 181.429 282.257 181.429C280.149 181.429 278.123 180.615 276.601 179.156C275.08 177.696 274.181 175.706 274.093 173.6V127.028C274.093 124.863 274.952 122.787 276.481 121.255C278.01 119.723 280.085 118.861 282.25 118.857Z" fill="#4CA9A3" />
      <path d="M42.6785 29.0855C44.8457 29.0855 46.9242 29.9464 48.4566 31.4789C49.989 33.0113 50.85 35.0897 50.85 37.2569V116.614C50.896 117.715 50.719 118.814 50.3294 119.844C49.9399 120.875 49.3459 121.816 48.5833 122.612C47.8206 123.407 46.905 124.04 45.8915 124.472C44.878 124.904 43.7875 125.127 42.6857 125.127C41.5838 125.127 40.4934 124.904 39.4799 124.472C38.4663 124.04 37.5507 123.407 36.7881 122.612C36.0254 121.816 35.4315 120.875 35.0419 119.844C34.6524 118.814 34.4753 117.715 34.5214 116.614V37.2284C34.5252 35.0674 35.3863 32.9963 36.9156 31.4696C38.445 29.943 40.5176 29.0855 42.6785 29.0855Z" fill="#4CA9A3" />
      <path d="M111.136 29.0855C113.295 29.0893 115.366 29.9479 116.894 31.4738C118.423 32.9997 119.285 35.0686 119.293 37.2284V116.586C119.339 117.686 119.162 118.785 118.772 119.816C118.383 120.847 117.789 121.788 117.026 122.583C116.263 123.378 115.348 124.011 114.334 124.443C113.321 124.876 112.23 125.099 111.128 125.099C110.027 125.099 108.936 124.876 107.923 124.443C106.909 124.011 105.994 123.378 105.231 122.583C104.468 121.788 103.874 120.847 103.485 119.816C103.095 118.785 102.918 117.686 102.964 116.586V37.2284C102.972 35.0661 103.836 32.995 105.368 31.4688C106.899 29.9425 108.973 29.0855 111.136 29.0855Z" fill="#4CA9A3" />
      <path d="M145.35 83.457C147.517 83.457 149.596 84.3179 151.128 85.8504C152.66 87.3828 153.521 89.4613 153.521 91.6285V170.986C153.567 172.086 153.39 173.185 153.001 174.216C152.611 175.247 152.017 176.188 151.255 176.983C150.492 177.778 149.576 178.411 148.563 178.844C147.549 179.276 146.459 179.499 145.357 179.499C144.255 179.499 143.165 179.276 142.151 178.844C141.138 178.411 140.222 177.778 139.459 176.983C138.697 176.188 138.103 175.247 137.713 174.216C137.324 173.185 137.147 172.086 137.193 170.986V91.6285C137.193 89.4637 138.052 87.3875 139.581 85.8554C141.11 84.3234 143.185 83.4608 145.35 83.457Z" fill="#4CA9A3" />
      <path d="M179.578 138.985C181.743 138.985 183.819 139.844 185.351 141.373C186.884 142.903 187.746 144.977 187.75 147.142V226.514C187.662 228.62 186.763 230.611 185.242 232.07C183.72 233.529 181.694 234.343 179.586 234.343C177.478 234.343 175.451 233.529 173.93 232.07C172.408 230.611 171.509 228.62 171.421 226.514V147.142C171.421 144.979 172.281 142.904 173.81 141.374C175.34 139.844 177.415 138.985 179.578 138.985Z" fill="#4CA9A3" />
      <path d="M248.036 138.985C250.199 138.985 252.274 139.844 253.804 141.374C255.333 142.904 256.193 144.979 256.193 147.142V226.514C256.105 228.62 255.206 230.611 253.685 232.07C252.163 233.529 250.137 234.343 248.029 234.343C245.92 234.343 243.894 233.529 242.373 232.07C240.851 230.611 239.952 228.62 239.864 226.514V147.142C239.868 144.977 240.731 142.903 242.263 141.373C243.795 139.844 245.871 138.985 248.036 138.985Z" fill="#4CA9A3" />
      <path d="M76.9071 0C79.0705 0 81.1453 0.859411 82.6751 2.38917C84.2048 3.91893 85.0643 5.99373 85.0643 8.15714V134.1C85.1103 135.201 84.9333 136.3 84.5437 137.33C84.1542 138.361 83.5602 139.302 82.7976 140.098C82.0349 140.893 81.1193 141.526 80.1058 141.958C79.0923 142.39 78.0018 142.613 76.9 142.613C75.7981 142.613 74.7077 142.39 73.6942 141.958C72.6806 141.526 71.765 140.893 71.0024 140.098C70.2397 139.302 69.6458 138.361 69.2562 137.33C68.8667 136.3 68.6896 135.201 68.7357 134.1V8.15714C68.7395 5.99242 69.6021 3.91766 71.1341 2.38831C72.6661 0.858954 74.7424 -3.30811e-06 76.9071 0Z" fill="#4CA9A3" />
      <path d="M213.807 119.229C214.879 119.229 215.941 119.44 216.932 119.851C217.923 120.262 218.822 120.864 219.58 121.623C220.338 122.382 220.938 123.283 221.347 124.274C221.756 125.265 221.966 126.328 221.964 127.4V253.371C221.876 255.478 220.977 257.468 219.456 258.927C217.934 260.386 215.908 261.201 213.8 261.201C211.692 261.201 209.665 260.386 208.144 258.927C206.622 257.468 205.724 255.478 205.636 253.371V127.4C205.636 125.233 206.497 123.154 208.029 121.622C209.561 120.089 211.64 119.229 213.807 119.229Z" fill="#4CA9A3" />
    </svg>
  );
};

export default LogoIcon;
