import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} id="Design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1435 251" fill="#4CA9A3">
      <path d="m753.1,126.98c-11.48,0-20.78-1.53-27.92-4.6-7.14-3.06-12.5-7.32-16.07-12.76-3.57-5.44-5.65-11.57-6.23-18.4h13.7c.35,5.55,2.22,10.04,5.62,13.45,3.39,3.41,7.87,5.93,13.44,7.55,5.56,1.62,11.68,2.43,18.35,2.43,9.83,0,17.62-1.71,23.36-5.12,5.74-3.41,8.61-8.47,8.61-15.19,0-4.05-1.43-7.32-4.3-9.81-2.87-2.49-6.67-4.54-11.42-6.16-4.74-1.62-9.92-3.09-15.54-4.43-5.62-1.33-11.27-2.75-16.95-4.25-5.68-1.5-10.89-3.41-15.63-5.73-4.74-2.31-8.55-5.29-11.42-8.94-2.87-3.64-4.3-8.3-4.3-13.97,0-9.37,3.66-17.01,10.98-22.91,7.31-5.9,18.23-8.85,32.75-8.85,10.3,0,18.79,1.53,25.46,4.6,6.67,3.07,11.68,7.09,15.02,12.06,3.34,4.98,5.18,10.36,5.53,16.14h-13.52c-.7-5.78-3.63-10.67-8.78-14.66-5.15-3.99-13.05-5.99-23.71-5.99-6.56,0-11.89.64-15.98,1.91-4.1,1.27-7.23,2.92-9.4,4.95-2.17,2.03-3.63,4.14-4.39,6.33-.76,2.2-1.14,4.28-1.14,6.25,0,3.82,1.43,6.91,4.3,9.29,2.87,2.37,6.67,4.34,11.42,5.9s9.98,2.98,15.72,4.25c5.74,1.27,11.44,2.69,17.12,4.25,5.68,1.56,10.89,3.53,15.63,5.9,4.74,2.37,8.55,5.41,11.42,9.11,2.87,3.7,4.3,8.45,4.3,14.23,0,10.65-4.1,18.83-12.29,24.56-8.2,5.73-19.44,8.59-33.72,8.59Z" />
      <path d="m847.21,125.57v-46.01l-48.82-70.25h15.98l39.34,56.55,39.34-56.55h15.98l-48.65,70.25v46.01h-13.17Z" />
      <path d="m956.6,126.98c-11.48,0-20.78-1.53-27.92-4.6-7.14-3.06-12.5-7.32-16.07-12.76-3.57-5.44-5.65-11.57-6.23-18.4h13.7c.35,5.55,2.22,10.04,5.62,13.45,3.39,3.41,7.87,5.93,13.44,7.55,5.56,1.62,11.68,2.43,18.35,2.43,9.83,0,17.62-1.71,23.36-5.12,5.74-3.41,8.61-8.47,8.61-15.19,0-4.05-1.43-7.32-4.3-9.81-2.87-2.49-6.67-4.54-11.42-6.16-4.74-1.62-9.92-3.09-15.54-4.43-5.62-1.33-11.27-2.75-16.95-4.25-5.68-1.5-10.89-3.41-15.63-5.73-4.74-2.31-8.55-5.29-11.42-8.94-2.87-3.64-4.3-8.3-4.3-13.97,0-9.37,3.66-17.01,10.98-22.91,7.31-5.9,18.23-8.85,32.75-8.85,10.3,0,18.79,1.53,25.46,4.6,6.67,3.07,11.68,7.09,15.02,12.06,3.34,4.98,5.18,10.36,5.53,16.14h-13.52c-.7-5.78-3.63-10.67-8.78-14.66-5.15-3.99-13.05-5.99-23.71-5.99-6.56,0-11.89.64-15.98,1.91-4.1,1.27-7.23,2.92-9.4,4.95-2.17,2.03-3.63,4.14-4.39,6.33-.76,2.2-1.14,4.28-1.14,6.25,0,3.82,1.43,6.91,4.3,9.29,2.87,2.37,6.67,4.34,11.42,5.9s9.98,2.98,15.72,4.25c5.74,1.27,11.44,2.69,17.12,4.25,5.68,1.56,10.89,3.53,15.63,5.9,4.74,2.37,8.55,5.41,11.42,9.11,2.87,3.7,4.3,8.45,4.3,14.23,0,10.65-4.1,18.83-12.29,24.56-8.2,5.73-19.44,8.59-33.72,8.59Z" />
      <path d="m1114.75,125.57V9.31h78.49v12.64h-65.32v38.99h56.74v12.82h-56.74v39.16h65.32v12.65h-78.49Z" />
      <path d="m1215.53,125.57V9.31h14.51l39.54,58.13,38.87-58.13h14.51v116.26h-12.51V29.33l-40.88,58.31-41.54-58.31v96.24h-12.51Z" />
      <path d="m1387.99,126.98c-11.48,0-20.78-1.53-27.92-4.6-7.14-3.06-12.5-7.32-16.07-12.76-3.57-5.44-5.65-11.57-6.23-18.4h13.7c.35,5.55,2.22,10.04,5.62,13.45,3.39,3.41,7.87,5.93,13.44,7.55,5.56,1.62,11.68,2.43,18.35,2.43,9.83,0,17.62-1.71,23.36-5.12,5.74-3.41,8.61-8.47,8.61-15.19,0-4.05-1.43-7.32-4.3-9.81-2.87-2.49-6.67-4.54-11.42-6.16-4.74-1.62-9.92-3.09-15.54-4.43-5.62-1.33-11.27-2.75-16.95-4.25-5.68-1.5-10.89-3.41-15.63-5.73-4.74-2.31-8.55-5.29-11.42-8.94-2.87-3.64-4.3-8.3-4.3-13.97,0-9.37,3.66-17.01,10.98-22.91,7.31-5.9,18.23-8.85,32.75-8.85,10.3,0,18.79,1.53,25.46,4.6,6.67,3.07,11.68,7.09,15.02,12.06,3.34,4.98,5.18,10.36,5.53,16.14h-13.52c-.7-5.78-3.63-10.67-8.78-14.66-5.15-3.99-13.05-5.99-23.71-5.99-6.56,0-11.89.64-15.98,1.91-4.1,1.27-7.23,2.92-9.4,4.95-2.17,2.03-3.63,4.14-4.39,6.33-.76,2.2-1.14,4.28-1.14,6.25,0,3.82,1.43,6.91,4.3,9.29,2.87,2.37,6.67,4.34,11.42,5.9s9.98,2.98,15.72,4.25c5.74,1.27,11.44,2.69,17.12,4.25,5.68,1.56,10.89,3.53,15.63,5.9,4.74,2.37,8.55,5.41,11.42,9.11,2.87,3.7,4.3,8.45,4.3,14.23,0,10.65-4.1,18.83-12.29,24.56-8.2,5.73-19.44,8.59-33.72,8.59Z" />
      <polygon points="1015.61 9.31 1015.61 21.78 1048.42 21.78 1048.42 125.57 1049.05 125.57 1061.59 125.57 1062.22 125.57 1062.22 21.78 1095.02 21.78 1095.02 9.31 1015.61 9.31" />
      <g>
        <path fill="#fff" d="m154.62,128.4c-8.94,0-16.89-2.07-23.62-6.14-6.73-4.07-12.03-9.67-15.75-16.64-3.71-6.94-5.59-14.7-5.59-23.04s1.79-16.23,5.32-23.11c3.55-6.92,8.7-12.49,15.32-16.56,6.62-4.08,14.52-6.15,23.46-6.15s16.99,2.07,23.55,6.15c6.56,4.08,11.68,9.65,15.23,16.56,3.53,6.88,5.32,14.66,5.32,23.11v6.55h-71.94c.77,4.24,2.27,8.19,4.47,11.76,2.45,3.97,5.79,7.17,9.92,9.51,4.13,2.34,9,3.53,14.48,3.53,5.79,0,10.69-1.29,14.57-3.82,3.92-2.55,7.01-5.89,9.19-9.92l.39-.72h16.96l-.73,1.88c-3.07,7.9-8,14.45-14.67,19.46-6.7,5.04-15.41,7.6-25.89,7.6Zm26.77-54.09c-1.28-6.32-4.18-11.72-8.62-16.06-4.77-4.65-11.16-7.01-19.02-7.01s-14.22,2.36-18.92,7.01c-4.39,4.34-7.31,9.74-8.68,16.07h55.24Z" />
        <path fill="#fff" d="m262.75,128.4c-11.39,0-20.79-1.57-27.94-4.68-7.23-3.14-12.74-7.57-16.37-13.17-3.61-5.56-5.74-11.92-6.33-18.89l-.13-1.5h16.24l.08,1.29c.32,5.15,2.04,9.34,5.12,12.47,3.15,3.2,7.38,5.6,12.58,7.14,5.3,1.56,11.22,2.36,17.61,2.36,9.35,0,16.82-1.64,22.2-4.88,5.23-3.15,7.77-7.69,7.77-13.89,0-3.63-1.23-6.48-3.75-8.69-2.66-2.34-6.28-4.3-10.73-5.85-4.58-1.58-9.67-3.05-15.12-4.36-5.49-1.31-11.09-2.74-16.66-4.23-5.63-1.51-10.87-3.45-15.58-5.78-4.82-2.38-8.75-5.49-11.67-9.26-2.99-3.85-4.51-8.8-4.51-14.71,0-9.69,3.79-17.7,11.27-23.81,7.39-6.04,18.5-9.1,33-9.1,10.25,0,18.85,1.58,25.56,4.69,6.75,3.14,11.89,7.33,15.3,12.46,3.39,5.12,5.29,10.74,5.65,16.7l.09,1.46h-15.95l-.15-1.22c-.64-5.34-3.37-9.92-8.1-13.63-4.78-3.75-12.32-5.65-22.4-5.65-6.26,0-11.39.62-15.26,1.83-3.8,1.2-6.72,2.74-8.68,4.59-1.95,1.84-3.28,3.77-3.95,5.73-.69,2.02-1.05,3.96-1.05,5.75,0,3.39,1.22,6.06,3.72,8.16,2.67,2.23,6.28,4.12,10.75,5.61,4.6,1.53,9.74,2.94,15.28,4.18,5.65,1.27,11.32,2.7,16.86,4.24,5.62,1.57,10.87,3.57,15.58,5.95,4.81,2.44,8.74,5.61,11.67,9.43,2.99,3.91,4.5,8.94,4.5,14.96,0,10.99-4.25,19.57-12.64,25.5-8.24,5.83-19.64,8.78-33.87,8.78Z" />
        <path fill="#fff" d="m438.57,127.03v-53.06c0-6.84-1.93-12.41-5.75-16.55-3.84-4.16-8.49-6.19-14.23-6.19s-11.12,2.02-15.43,6.01c-4.28,3.96-6.45,9.64-6.45,16.9v52.88h-15.68v-53.06c0-6.84-1.93-12.41-5.75-16.55-3.84-4.16-8.49-6.19-14.23-6.19s-11.12,2.02-15.43,6.01c-4.28,3.96-6.45,9.64-6.45,16.9v52.88h-15.68V38.14h15.68v10.07c1.87-2.27,4.25-4.45,7.11-6.54,4.47-3.26,10.42-4.92,17.69-4.92,5.97,0,11.58,1.61,16.66,4.77,4.45,2.77,8.08,6.57,10.84,11.3,1.11-1.57,2.55-3.34,4.29-5.28,2.57-2.87,5.98-5.41,10.13-7.54,4.2-2.16,9.28-3.25,15.1-3.25s11.19,1.51,16.21,4.5c5.01,2.98,9.14,7.29,12.29,12.82,3.15,5.53,4.75,12.1,4.75,19.54v53.4h-15.68Z" />
        <path fill="#fff" d="m510.8,128.4c-8.61,0-16.25-2.07-22.7-6.16-6.44-4.08-11.51-9.68-15.05-16.65-3.53-6.94-5.32-14.68-5.32-23.02s1.79-16.22,5.32-23.11c3.54-6.91,8.6-12.48,15.04-16.56,6.45-4.09,14.09-6.16,22.7-6.16,7.32,0,13.71,1.45,19,4.3,4.04,2.18,7.54,4.98,10.45,8.35v-11.27h15.68v88.89h-15.68v-11.17c-2.91,3.31-6.41,6.07-10.45,8.25-5.29,2.85-11.69,4.3-19,4.3Zm1.72-77.17c-6.02,0-11.27,1.43-15.61,4.25-4.36,2.84-7.73,6.65-10.02,11.33-2.31,4.72-3.48,10.02-3.48,15.76s1.17,10.9,3.48,15.68c2.29,4.74,5.66,8.58,10.02,11.41,4.34,2.82,9.59,4.26,15.61,4.26s11.34-1.43,15.5-4.24c4.19-2.83,7.41-6.66,9.6-11.4,2.2-4.79,3.32-10.07,3.32-15.7s-1.12-11.05-3.32-15.78c-2.18-4.68-5.41-8.49-9.6-11.32-4.17-2.81-9.39-4.24-15.5-4.24Z" />
        <path fill="#fff" d="m575.46,127.03V38.14h15.68v16.11s.05-.09.07-.13c1.37-2.57,3.4-5.2,6.01-7.82,2.63-2.63,5.98-4.9,9.96-6.74,4.01-1.86,8.82-2.8,14.27-2.8h2.24v15.68h-2.93c-6.09,0-11.41,1.67-15.79,4.98-4.44,3.35-7.89,7.74-10.25,13.06-2.38,5.36-3.58,11.17-3.58,17.27v39.28h-15.68Z" />
        <polygon fill="#fff" points="651.12 127.03 651.12 52.92 633.3 52.92 633.3 38.91 651.12 38.91 651.12 8.34 666.98 8.34 666.98 38.91 687.07 38.91 687.07 52.92 666.98 52.92 666.98 127.03 651.12 127.03" />
      </g>
      <g>
        <rect x="1" y="117.86" width="11.6" height="44.63" rx="5.8" ry="5.8" />
        <rect x="188.37" y="144.32" width="11.6" height="44.63" rx="5.8" ry="5.8" />
        <rect x="47.84" y="63.93" width="11.6" height="97.46" rx="5.8" ry="5.8" />
        <rect x="141.53" y="145.19" width="11.6" height="97.46" rx="5.8" ry="5.8" />
        <rect x="24.42" y="83.57" width="11.6" height="65.77" rx="5.8" ry="5.8" />
        <rect x="71.26" y="83.57" width="11.6" height="65.77" rx="5.8" ry="5.8" />
        <rect x="118.11" y="158.84" width="11.6" height="65.77" rx="5.8" ry="5.8" />
        <rect x="164.95" y="158.84" width="11.6" height="65.77" rx="5.8" ry="5.8" />
        <rect x="94.69" y="120.54" width="11.6" height="65.77" rx="5.8" ry="5.8" />
      </g>
    </svg>
  );
};

export default LogoFull;
