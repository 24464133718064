import * as React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";


const Icon: React.FunctionComponent<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M12 18.1625V2L5 6.03833L5.00424 22.2062L12 18.1625L12 21.9565L19 21.9524V14.12L12 18.1625Z" />
        </SvgIcon>
    );
};

export default Icon;