
import * as React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";


const Icon: React.FunctionComponent<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props} focusable="false" viewBox="0 0 59 74" aria-hidden="true">
            <path fill="#E03875" d="M31.778 75.59a31.759 31.759 0 1 1 21.5-55.12l-7.51 7.88a20.87 20.87 0 1 0 0 31l7.51 7.85a31.66 31.66 0 0 1-21.5 8.39ZM57.338 59.7a2.6 2.6 0 1 1 0-5.2 2.6 2.6 0 0 1 0 5.2Z" />
            <path fill="#E03875" d="M31.577 50.71a6.88 6.88 0 1 1 0-13.76 6.88 6.88 0 0 1 0 13.76ZM60.327 50.81a2.6 2.6 0 1 1 2.6-2.6 2.61 2.61 0 0 1-2.6 2.6ZM52.588 50.51a2.6 2.6 0 1 1 0-5.2 2.6 2.6 0 0 1 0 5.2ZM60.327 42.06a2.6 2.6 0 1 1 2.6-2.6 2.61 2.61 0 0 1-2.6 2.6ZM52.588 42.36a2.6 2.6 0 1 1 0-5.2 2.6 2.6 0 0 1 0 5.2ZM57.487 33.31a2.6 2.6 0 1 1 0-5.2 2.6 2.6 0 0 1 0 5.2Z" />
        </SvgIcon>
    );
};

export default Icon;